import * as React from "react"
import Helmet from "react-helmet"
import "../styles/styles.scss"

// markup
const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <body className="-blue"/>
      </Helmet>
      <div className="page-404">
        <div className="page-404__text">
          404
        </div>
        <div className="page-404__small">
          , dude
        </div>
      </div>
    </>
  )
}

export default NotFoundPage
